<template>
    <div class="headerW">
        <div class="header">
            <div class="headerLogo" @click="jumpUrl('/')">
                <img src="../../assets/logo.png" alt="">
            </div>

            <div class="headerSearchBox">
                <div class="coordinate">
                    <img class="coordinateIcon" src="../../assets/coordinateIcon.png" alt="">
                    <span class="coordinateText">杭州</span>
                </div>
                <div class="searchBox">
                    <input class="searchIpt" type="text" placeholder="搜点什么..." v-model="keyword"
                        @keyup.enter="jumpSearchPage">
                    <img class="searchIcon" src="../../assets/searchIcon.png" alt="">
                </div>
            </div>

            <div class="headerNav">
                <!-- <div class="headerNavItem" @click="jumpUrl('/recommendation')">
                    <img style="width:1.35417vw; height:1.35417vw" src="../../assets/goodsreCommendationIcon.png" alt="">
                </div> -->
                <div class="headerNavItem" @click="jumpUrl('ShowCircle')">
                    <img style="width:1.51042vw; height:1.45833vw" src="../../assets/showCircleIcon.png" alt="">
                </div>
                <div class="headerNavItem" @click="jumpUrl('liveList')">
                    <img style="width:1.51042vw; height:1.35417vw" src="../../assets/liveBroadcastIcon.png" alt="">
                </div>
                <el-popover class="headerNavItem" placement="bottom" trigger="click">
                    <div class="headerNavItem" slot="reference">
                        <img style="width:1.40625vw; height:1.30208vw" src="../../assets/merchantsSettledIcon.png" alt="">
                    </div>
                    <div class="loadDowntips">
                        <img style="width: 100%;height:100%;" src="../../assets/qr.png" alt="扫码下载">
                        <!-- <div class="text">
                            即将上线,敬请期待
                            下载APP可进行入驻申请
                        </div> -->
                    </div>
                </el-popover>
                <el-popover class="headerNavItem" placement="bottom" trigger="click">
                    <div class="headerNavItem" slot="reference">
                        <img style="width:1.19792vw; height:1.30208vw" src="../../assets/personalCenterIcon.png" alt="">
                    </div>
                    <div class="loadDowntips">
                        <img style="width: 100%;height:100%;" src="../../assets/qr.png" alt="扫码下载">

                        <!-- <div class="text">
                            即将上线,敬请期待
                            扫码下载可查看个人中心
                        </div> -->
                    </div>
                </el-popover>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            keyword: ''
        }
    },

    methods: {
        jumpUrl(e) {
            console.log(e,'e');
            this.$router.push({
                path: e,
            })
        },
        jumpSearchPage(e) {
            if (e.key == 'Enter') {
                this.$router.push({
                    path: 'searchVideo',
                    query: {
                        keyword: this.keyword,
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.headerW {
    border-bottom: 1px solid #E1DFDF;

    .header {
        margin: auto;
        width: 1180px;
        height: 97px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 120px;

        .headerLogo {
            cursor: pointer;
            display: flex;
            width: 151.6px;
            height: 84.8px;

            img {
                width: 151.6px;
                height: 84.8px;
            }
        }

        .headerSearchBox {
            display: flex;
            align-items: center;
            padding: 0 20px;
            width: 602px;
            height: 41px;
            background: #F9F9F9;
            border-radius: 4px;
            border: 1px solid #FF2442;

            .coordinate {
                display: flex;
                align-items: center;
                height: 28px;
                padding-right: 20px;
                border-right: 1px solid #FF2442;

                .coordinateIcon {
                    width: 23px;
                    height: 23px;
                    margin-right: 10px;
                }

                .coordinateText {
                    font-size: 20px;
                    color: #666666;
                }
            }

            .searchBox {
                flex: 1;
                display: flex;
                align-items: center;
                padding-left: 20px;

                .searchIpt {
                    flex: 1;
                    height: 20px;
                    border: none;
                    font-size: 14px;
                    background: #F9F9F9;
                }

                .searchIcon {
                    width: 19px;
                    height: 19px;
                }
            }
        }

        .headerNav {
            display: flex;
            align-items: center;

            .headerNavItem {
                cursor: pointer;
                display: flex;
                margin-right: 35px;

                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}

.loadDowntips {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;

    img {
        width: 97px;
        height: 97px;
        margin: 11px;
    }

    .text {
        font-size: 13px;
        color: #666666;
    }
}

.feedbackDialog {
    .feedbackDialogTop {
        margin-bottom: 65px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .close {
            width: 20px;
            height: 20px;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>

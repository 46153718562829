<template>
  <div class="footerW">
    <div class="footer">
      <div class="footerContent">
        天街喵app致力于将未来生活装进口袋，努力提供便捷服务与注重时间概念，鼓励用户抒发，记录各自的生活态度与主张；提倡用户利用自身技能，兴趣特长为拓宽生活圈子在空闲时间进行互动或有偿社交，及时收获并分享时代高速发展给予的养分与便利，共同成长! 随时随地感受分享生活与学习融合的理念！ 
        <!-- <div class="footerUl">
          <div class="footerUlTitle">下载APP</div>
          <div class="footerLiBox">
            <div class="footerLi">我的主页</div>
            <div class="footerLi">我的收益</div>
            <div class="footerLi">我的足迹</div>
            <div class="footerLi">商家入驻</div>
          </div>
        </div> -->

        <!-- <div class="footerUl">
          <div class="footerUlTitle">快速链接</div>
          <div class="footerLiBox">
            <div class="footerLi">商品推荐</div>
            <div class="footerLi">直播频道</div>
            <div class="footerLi">秀圈风采</div>
            <div class="footerLi">平台客服</div>
          </div>
        </div> -->

        <!-- <div class="footerUl">
          <div class="footerUlTitle">关于我们</div>
          <div class="footerLiBox">
            <div class="footerLi">隐私政策</div>
            <div class="footerLi">服务条款</div>
            <div class="footerLi">营业执照</div>
            <div class="footerLi">营业许可证</div>
          </div>
        </div> -->
      </div>

      <div class="callMy">
        <div class="callMytitle">联系我们</div>
        <div class="myInfo">
          <div>地址：浙江省杭州市临平区南苑街道新城路108号807室</div>
          <div>邮箱：ppzx168@88.com</div>
          <div style="display: flex;">
            <div style="cursor: pointer; margin-right: 1.5625vw;" @click="userAgreement">用户协议</div>
            <a href="https://www.ppzx168.com.cn/policy/privacyPolicy.html" target="_blank"
              class="copyright_license">隐私政策</a>
          </div>
          <div style="cursor: pointer;" @click="relesRead('BUSINESS_LICENSE')">营业执照</div>
          <div style="cursor: pointer;" @click="relesRead('BUSINESS_PERMIT')">经营许可证</div>
          <!-- <div>电话：181 5718 6368</div> -->
        </div>
      </div>

      <div class="bigBrand">
        <img class="bigBrandLOGO" src="../../assets/bigBrandLOGO.png" alt="" />
        <div class="brandSubject">与你一起探索优质生活</div>
      </div>
    </div>

    <div class="footerBottom">
      <img class="footerBottomLogo" src="../../assets/logo.png" alt="" />
      
      <div class="copyright">
        <a style="text-decoration: none;color: #999999;" href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank">
          版权所有：浙江添丽网络科技有限公司 浙ICP备20029969号-2
        </a>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33011302000468" style="font-size: 0.625vw;color: #999999;"> <img src="../../assets/beian.png" alt=""> <span> 浙公网安备 33011302000468号</span></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
    };
  },

  methods: {
    jumpUrl(e) {
      console.log(e);
      this.$router.push({
        path: e,
      });
    },
    jumpSearchPage(e) {
      if (e.key == "Enter") {
        this.$router.push({
          path: "search",
          query: {
            keyword: this.keyword,
          },
        });
      }
    },
    userAgreement() {
      this.$router.push({
        path: "userAgreement"
      });
    },

    relesRead(type) {
      let { href } = this.$router.resolve({
        path: "RelesRead",
        query: {
          type: type,
        },
      });
      window.open(href, '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
.footerW {
  position: relative;
  background-image: url(../../assets/indexFooter.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 375px;

  .footer {
    // overflow: hidden;
    margin: auto;
    width: calc(1400px - 240px);
    padding: 0 120px;
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;

    // justify-content: space-between;
    .footerContent {
      display: flex;
      margin-top: 86px;
      font-size: 14px;
      width: 380px;
      color: #666666;
      text-align: justify;

      .footerUl {
        .footerUlTitle {
          font-size: 14px;
          color: #333333;
          margin-bottom: 10px;
        }

        .footerLiBox {
          margin-right: 80px;
          height: 128px;
          font-size: 12px;
          color: #666666;
          line-height: 32px;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .callMy {
      margin-top: 86px;
      margin-right: 100px;

      .callMytitle {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
      }

      .myInfo {
        height: 128px;
        font-size: 12px;
        color: #666666;
        line-height: 32px;
      }
    }

    .bigBrand {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 86px;

      .bigBrandLOGO {
        display: flex;
        width: 116px;
        height: 116px;
        margin-bottom: 20px;
      }

      .brandSubject {
        font-size: 12px;
        color: #666666;
      }
    }
  }

  .footerBottom {
    width: 1440px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 82px;
    position: absolute;
    bottom: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 50%);

    .footerBottomLogo {
      width: 126.3px;
      height: 70px;
    }

    .copyright {
      font-size: 12px;
      color: #999999;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}
</style>

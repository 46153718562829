import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './config/rem'
import axios from "axios";
import { Button, Select, Carousel, CarouselItem, Image, Icon, Tooltip, Backtop, Popover, Dialog, Empty } from 'element-ui';
import 'normalize.css/normalize.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import 'element-ui/lib/theme-chalk/index.css';
import InfiniteLoading from 'vue-infinite-loading';
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.use(InfiniteLoading, { /* 配置 */ });
Vue.use(VueAwesomeSwiper)
Vue.use(Button)
Vue.use(Select)
Vue.use(Carousel) //轮播图
Vue.use(CarouselItem) //轮播图
Vue.use(Image) //图片
Vue.use(Icon) //图标
Vue.use(Tooltip) //骨架
Vue.use(Backtop) //置顶
Vue.use(Popover) //置顶
Vue.use(Dialog) //置顶
Vue.use(Button) //置顶
Vue.use(Empty) //置顶


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <div id="app">
    <!-- 头部 -->
    <div class="header-content" v-show="$route.name !== 'player'">
      <Head></Head>
    </div>
    <router-view />
    <!-- 底部 -->
    <Footer v-show="$route.name !== 'player'"></Footer>
  </div>
</template>

<script>
import Head from "@/components/header/header.vue";
import Footer from "@/components/footer/footer.vue";

export default {
  name: "App",
  components: {
    Head,
    Footer,
  },
  
  // beforeCreate() {
  //   if (
  //     navigator.userAgent.match(/Mobi/i) ||
  //     navigator.userAgent.match(/Android/i) ||
  //     navigator.userAgent.match(/iPhone/i)
  //   ) {
  //     // 当前设备是移动设备
  //     window.location.href="https://user.ppzx168.com.cn/#/";
  //   }
  // },
};
</script>

<style>
input {
  outline: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  word-break: break-all;
  /* break-all(允许在单词内换行。) */
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2;
  /** 显示的行数 **/
}

.nowrapEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image-slot {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}
</style>

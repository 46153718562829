import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件
const Index = () => import(/* webpackChunkName: "index" */ '../views/index/index.vue')
const Search = () => import(/* webpackChunkName: "index" */ '../views/search/search.vue')
const Recommendation = () => import(/* webpackChunkName: "index" */ '../views/recommendation/recommendation.vue')
const ShowCircle = () => import(/* webpackChunkName: "videoPlayer" */ '../views/showCircle/showCircle.vue')
const Player = () => import(/* webpackChunkName: "videoPlayer" */ '../views/player/player.vue')
const DownloadPage = () => import(/* webpackChunkName: "DownloadPage" */ '../views/downloadPage/downloadPage.vue')
const Live = () => import(/* webpackChunkName: "live" */ '../views/live/live.vue')
const LivePlayer = () => import(/* webpackChunkName: "livePlayer" */ '../views/livePlayer/livePlayer.vue')
const Video = () => import(/* webpackChunkName: "video" */ '../components/HelloWorld.vue')
const SearchVideo = () => import(/* webpackChunkName: "video" */ '../views/searchVideo/searchVideo.vue')
const UserAgreement = () => import(/* webpackChunkName: "video" */ '../views/userAgreement/userAgreement.vue')
const RelesRead = () => import(/* webpackChunkName: "RelesRead" */ '../views/rulesRead/rulesRead.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'//重定向
  },
  {
    name: 'index',
    path: '/',
    component: Index
  },

  {
    name: 'search',
    path: '/search',
    component: Search
  },
  {
    name: 'recommendation',
    path: '/recommendation',
    component: Recommendation
  },
  {
    name: 'showCircle',
    path: '/showCircle',
    component: ShowCircle
  },
  {
    name: 'player',
    path: '/player',
    component: Player
  },
  {
    name: 'downloadPage',
    path: '/downloadPage',
    component: DownloadPage
  },
  {
    name: 'liveList',
    path: '/liveList',
    component: Live
  },
  {
    name: 'livePlayer',
    path: '/livePlayer',
    component: LivePlayer
  },
  {
    name: 'Video',
    path: '/Video',
    component: Video
  },
  {
    name: 'SearchVideo',
    path: '/SearchVideo',
    component: SearchVideo
  },
  {
    name: 'UserAgreement',
    path: '/UserAgreement',
    component: UserAgreement
  },
  {
    name: 'RelesRead',
    path: '/RelesRead',
    component: RelesRead
  },
]

const router = new VueRouter({
  routes
})

export default router
